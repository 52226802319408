import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  type Orgaentity,
  type OrgaentityUpdate,
  orgaentities,
  orgaentity,
} from '@dep/common/core-api/types/orgaentity.type';
import {
  BaseResponse,
  BaseSuccess,
  IDeviceMetrics,
  ILicense,
  IOrgaentityAll,
  IOrgaentityClient,
  IUserRoleList,
  ListResponse,
  RoleRight,
} from '@dep/common/interfaces';
import {
  IDeviceSettings,
  IEnergySettings,
} from '@dep/common/interfaces/device-api/device-attributes.interface';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';

import {
  createOrgaentity,
  getDeviceMetrics,
  getOrgaentity,
  rebootDevice,
  removeOrgaentity,
  treeClients,
  updateDeviceSettings,
} from '@dep/frontend/appsync.queries';
import { ClientModel } from '@dep/frontend/models/client';
import { AppsyncService } from '@dep/frontend/services/appsync.service';
import { UserService } from '@dep/frontend/services/user.service';
import { environment } from 'src/environments/environment';

// TODO: Subject to change. Should be replaced by generic License Enum when there's a proper concept.
export enum OneMirrorLicense {
  NAME = 'OneMirror Server',
  LICENSE_BASIC = 'OneMirror Server Basic',
  LICENSE_PRO = 'OneMirror Server Pro',
}

@Injectable({
  providedIn: 'root',
})
export class OrgaentitiesService {
  constructor(
    private logger: NGXLogger,
    private appsyncService: AppsyncService,
    private userService: UserService,
    private http: HttpClient,
  ) { }

  public async getClientsTree(
    startAtId?: number,
    searchTerm?: string,
    omitClients?: boolean,
  ): Promise<ListResponse<IOrgaentityAll>> {
    const clients = await this.appsyncService.query<ListResponse<IOrgaentityAll>>(treeClients, {
      id: startAtId,
      searchTerm,
      omitClients,
    });

    // If the user is allowed to see the Handover Companion, add it to all LOCATION nodes under the node/orgaentity which the role was assigned to.
    const canReadHOC = await this.userService.hasRight(RoleRight.HANDOVERCOMPANION_READ);
    if (canReadHOC && !omitClients) {
      const userRoles = await this.userService.getRoles();
      clients.items = this.insertHandoverCompanions(clients.items, userRoles.items) as IOrgaentityAll[];
    }

    this.logger.debug('Got clients tree', clients);

    return clients;
  }

  private insertHandoverCompanions(
    client: IOrgaentityAll | IOrgaentityAll[] | undefined,
    roles: IUserRoleList[],
  ): IOrgaentityAll | IOrgaentityAll[] | undefined {
    try {
      if (client === undefined) {
        return client;
      }
      if (Array.isArray(client)) {
        for (let i = 0; i <= client.length; i++) {
          if (client[i] !== undefined) {
            client[i] = this.insertHandoverCompanions(client[i], roles) as IOrgaentityAll;
          }
        }
        return client;
      }

      // Check for correct path and rights to prevent inserting HandoverCompanions (HC) under every LOCATION node.
      // HCs are inserted on every LOCATION node, under the node/orgaentity with HANDOVERCOMPANION_READ.
      if (client.type === 'LOCATION' && this.userService.hasRightOnPathSync(roles, RoleRight.HANDOVERCOMPANION_READ, client.path)) {
        // Insert HC only if `children` are available (not `null`).
        // If `children` is `null`, they will be loaded when the user expands them, so we cannot insert HC here yet.
        if (Array.isArray(client.children)) {
          if (client.children.length === 0 || client.children[0].type !== 'HANDOVERCOMPANION') {
            client.children.unshift({
              id: 99999,
              parentOrgaentityId: client.id,
              // Path should be set for `rowHasContextMenu` pipe to check right access on path.
              path: client.path + '99999',
              type: 'HANDOVERCOMPANION',
              name: 'Handover:Assistant',
            });
          }
        }
      } else if (client.children) {
        for (let i = 0; i <= client.children.length; i++) {
          if (client.children[i] !== undefined) {
            client.children[i] = this.insertHandoverCompanions(client.children[i], roles) as IOrgaentityAll;
          }
        }
      }

      return client;
    } catch (e) {
      this.logger.error('insertHandoverCompanions failed', e, client);
      throw e;
    }
  }

  public async getOrgaentity(id: number): Promise<IOrgaentityAll> {
    return this.appsyncService.query<IOrgaentityAll>(getOrgaentity, { id })
      .then((oe) => {
        this.logger.debug('OE', oe);

        return oe;
      });
  }

  public async getOrgaentityV2(orgaentityId: number): Promise<Orgaentity> {
    this.logger.debug('Getting orgaentity', orgaentityId);

    try {
      const response = await lastValueFrom(this.http.get<unknown>(
        `${environment.config.coreApiGateway.url}/orgaentities/${orgaentityId}`,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));
      return orgaentity.parse(response);
    } catch (error) {
      this.logger.error('Could not get orgaentity', error);
      throw error;
    }
  }

  public async createOrgaentity(input: ClientModel): Promise<BaseResponse> {
    this.logger.debug('Creating orgaentity', input);
    const response = await this.appsyncService.mutate<BaseResponse>(createOrgaentity, {
      ...input,
      data: this.getOrgaentityJsonValue(input.data),
    });
    this.logger.debug('Created orgaentity', response);
    return response;
  }

  /**
   * Update orgaentity using core-api.
   *
   * @param input - Values of the orgaentity which should be updated
   * @returns Updated orgaentity
   */
  public async updateOrgaentity(input: OrgaentityUpdate): Promise<Orgaentity> {
    this.logger.debug('Updating orgaentity', input);

    try {
      const response = await lastValueFrom(this.http.put<unknown>(
        `${environment.config.coreApiGateway.url}/orgaentities/${input.id}`,
        input,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));
      return orgaentity.parse(response);
    } catch (error) {
      this.logger.error('Could not update orgaentity', error);
      throw error;
    }
  }

  /**
   * Return the JSON stringified `data` or `dataEnhanced` object.
   * If the object is empty, return `null`.
   */
  private getOrgaentityJsonValue(data?: Record<string, string> | null): string | null {
    if (data && typeof data === 'object' && Object.keys(data).length === 0) {
      return null;
    }
    return JSON.stringify(data);
  }

  public async removeOrgaentity(id: number): Promise<BaseResponse> {
    this.logger.debug('Removing orgaentity', id);
    const response = await this.appsyncService.mutate<BaseResponse>(removeOrgaentity, { id });
    this.logger.debug('Removed orgaentity', response);
    return response;
  }

  /**
   * Get all ancestor orgaentities.
   *
   * Guarantees order of the nodes from highest node (first array element) to lowest (last element),
   * e. g. `[{ path: '/1/', ...}, { path: '/1/2/', ... }, { path: '/1/2/3/', ... }]`.
   *
   * @param orgaentityId - Orgaentity ID for which to get the ancestors
   * @returns Ancestor nodes
   */
  public async getAncestorNodes(orgaentityId: number): Promise<Orgaentity[]> {
    this.logger.debug('Getting ancestor orgaentities');

    try {
      const response = await lastValueFrom(this.http.get<unknown>(
        `${environment.config.coreApiGateway.url}/orgaentities/${orgaentityId}/ancestors`,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));
      return orgaentities.parse(response);
    } catch (error) {
      this.logger.error('Could not fetch orgaentities', error);
      throw error;
    }
  }

  public async getDeviceMetrics(deviceName: string): Promise<IDeviceMetrics> {
    return this.appsyncService.query<IDeviceMetrics>(getDeviceMetrics, { deviceName })
      .then((deviceMetrics) => {
        this.logger.debug('Device Metrics', deviceMetrics);
        return deviceMetrics;
      });
  }

  public async rebootDevice(deviceName: string): Promise<BaseSuccess> {
    return this.appsyncService.mutate<BaseSuccess>(rebootDevice, { deviceName })
      .then((response) => {
        this.logger.debug('Reboot Device Response: ', response);
        return response;
      });
  }

  public async updateDeviceSettings(deviceName: string, settings: IEnergySettings): Promise<{ desired: IDeviceSettings, reported: IDeviceSettings }> {
    this.logger.debug('updateDeviceSettings', deviceName, settings);

    const response = await this.appsyncService.mutate<{ desired: IDeviceSettings, reported: IDeviceSettings }>(updateDeviceSettings, {
      deviceName,
      settings,
    });
    this.logger.debug('Reboot Device Response: ', response);
    return response;
  }

  public getDeviceType(playername: string): string | undefined {
    this.logger.debug(`Getting device type for player: ${playername}`);
    const clientTypeAbbr = (playername.slice(-4, playername.length - 2)).toLowerCase();
    if (clientTypeAbbr === 'ds' || clientTypeAbbr === 'os' || clientTypeAbbr === 'st') {
      return 'Non-Touch (16:9)';
    }
    if (clientTypeAbbr === 'dt' || clientTypeAbbr === 'ot') {
      return 'Touch (16:9)';
    }
    if (clientTypeAbbr === 'wa') {
      return 'Stagewall (32:9)';
    }

    return undefined;
  }

  /**
   * Determines the client type abbreviation by looking at the 11th and 12th character.
   * Falls back to "os" if determination fails.
   *
   * @param {string} playername - The client's hostname
   * @returns The client type abbreviation (lowercased)
   */
  public getClientTypeAbbreviation(playername: string): 'os' | 'ot' | 'st' | 'wa' {
    try {
      const clientTypeAbbr = (playername.substring(11, 13)).toLowerCase();
      if (clientTypeAbbr === 'ds' || clientTypeAbbr === 'os') {
        return 'os';
      } if (clientTypeAbbr === 'dt' || clientTypeAbbr === 'ot') {
        return 'ot';
      } if (clientTypeAbbr === 'st') {
        return 'st';
      } if (clientTypeAbbr === 'wa') {
        return 'wa';
      }
    } catch (e) {
      // Could not determine client type abbreviation.
    }

    // Fallback 'os'.
    return 'os';
  }

  public getOEDataValue(item: IOrgaentityAll, key: string): string | null {
    if (item.data) {
      const foundItem = item.data.find((d) => d.k === key);
      if (foundItem && foundItem.v) {
        return foundItem.v;
      }
    }
    return null;
  }

  public getOneMirrorLicense(client: IOrgaentityClient): ILicense {
    const proLicense = this.getLicense(client, OneMirrorLicense.LICENSE_PRO);
    return proLicense.name ? proLicense : this.getLicense(client, OneMirrorLicense.LICENSE_BASIC);
  }

  public getLicense(client: IOrgaentityClient, licenseName: string): ILicense {
    let foundLicense: ILicense = {
      id: '',
      name: '',
      validity: '',
    };

    if (!client.licenses || client.licenses.length === 0) {
      return foundLicense;
    }

    for (const license of client.licenses) {
      if (license.name === licenseName) {
        foundLicense = license;
      }
    }

    return foundLicense;
  }

  /**
   * Convert an array of key-value objects to one plain object.
   *
   * @param keyValueObjectArray - Array of key-value objects
   * @returns Plain `Record` object
   */
  public static convertKeyValueObjectArrayToPlainObject(keyValueObjectArray: { k: string; v: string; }[]): Record<string, unknown> {
    const plainObject: Record<string, unknown> = {};
    for (const keyValueObject of keyValueObjectArray) {
      plainObject[keyValueObject.k] = keyValueObject.v;
    }
    return plainObject;
  }
}
